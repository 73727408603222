.enter {
  position: absolute;

  &__elements {
    // If you want 'NBFG' at the top center - just the 4 below...
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    // If you want 'NBFG' in center center above button - add these on...
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 2;
  }

  &__button {
    // position: absolute;
    width: 200px;
    // top: 50%;
    // left: 0;
    // right: 0;
    // margin-left: auto;
    // margin-right: auto;
    padding: 16px;
    @include size(20);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: $primaryColor;
    color: inherit;
    border: none;
    cursor: pointer;
    outline: inherit;
  }

  &__video {
    max-width: 1440px;
    z-index: 1;
    position: relative;
  }

  &__video-element {
    width: 100%;
  }
}
