.about {
  display: flex;
  flex-direction: column;
  background: $secondaryTextColor;
  padding-top: var(--navbar-height);
  padding-right: 24px;
  padding-bottom: 24px;
  padding-left: 24px;

  @media screen and (min-width: $smallDesktop) {
    padding-right: 72px;
    padding-bottom: 48px;
    padding-left: 72px;
  }

  &__text-content {
    color: $textColor;
    margin: 24px 0px;
  }

  &__title {
    color: $primaryColor;
    @include size(40);
    line-height: 1.2;
    margin-bottom: 48px;
    font-weight: 700;

    @media screen and (min-width: $smallDesktop) {
      @include size(48);
    }
  }

  &__description {
    @include size(16);

    p {
      margin-bottom: 24px;
      font-weight: 500;
    }
  }

  &__image {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
}
