.footer {
  width: 100%;
  padding: 0px 12px;

  @media screen and (min-width: $smallDesktop) {
    padding: 0px 48px;
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 24px 28px;
    flex-wrap: wrap-reverse;

    @media screen and (min-width: $smallMobile) {
      flex-wrap: nowrap;
      white-space: nowrap;
      gap: 24px;
    }

    @media screen and (min-width: $mediumMobile) {
      padding: 24px 18px;
      gap: 28px;
      white-space: unset;
    }

    @media screen and (min-width: $smallDesktop) {
      padding: 24px 62px 24px 40px;
    }

    &--left {
      display: flex;
      flex-direction: column;
      margin: 24px 0px 0px 0px;

      @media screen and (min-width: $smallMobile) {
        margin: 0px;
      }
    }

    &--right {
      display: flex;
      flex-direction: column;
      @include size(12);
    }

    &--details {
      margin-bottom: 12px;
      text-decoration: underline;
      color: $secondaryColor;
      width: fit-content;

      &:link {
        color: $secondaryColor;
      }

      &:visited {
        color: $secondaryColor;
      }

      &:hover {
        color: $secondaryColor;
      }

      &:active {
        color: $secondaryColor;
      }
    }
  }

  &__line {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ec2c9121;
    padding: 0;
    width: 95%;
  }
}

.contact {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  background: $secondaryTextColor;
  color: $textColor;
  align-items: center;

  &__line {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid $primaryColor;
    padding: 0;
    width: 95%;
  }

  &__information {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: 24px 18px;
    margin-bottom: 24px;

    @media screen and (min-width: $smallDesktop) {
      flex-direction: row;
      padding: 24px 40px;
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
  }
}

.text-content {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $smallDesktop) {
    align-items: center;
  }

  &__newsletter {
    display: flex;
    flex-direction: column;
    max-width: 240px;
    margin-bottom: 24px;
  }

  &__link {
    text-decoration: underline;
    @include size(14);
    margin-top: 12px;
    color: $secondaryColor;
    width: fit-content;

    &:link {
      color: $secondaryColor;
    }

    &:visited {
      color: $secondaryColor;
    }

    &:hover {
      color: $secondaryColor;
    }

    &:active {
      color: $secondaryColor;
    }
  }

  &__title {
    color: $primaryColor;
    @include size(36);
    line-height: 1.2;
    margin: 24px 0px;
    font-weight: 700;
    width: 100%;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
}

.enquiries {
  &__text {
    @include size(14);
  }

  &__email {
    text-decoration: underline;
    @include size(14);
    margin-bottom: 24px;
    color: $secondaryColor;
    width: fit-content;

    &:link {
      color: $secondaryColor;
    }

    &:visited {
      color: $secondaryColor;
    }

    &:hover {
      color: $secondaryColor;
    }

    &:active {
      color: $secondaryColor;
    }
  }
}

.details {
  &__signature {
    color: $textColor;
    @include size(12);
    margin: 0px 0px 12px 0px;
  }

  &__portfolio {
    text-decoration: underline;
    color: $secondaryColor;

    &:link {
      color: $secondaryColor;
    }

    &:visited {
      color: $secondaryColor;
    }

    &:hover {
      color: $secondaryColor;
    }

    &:active {
      color: $secondaryColor;
    }
  }
}

.logos {
  display: flex;
  align-items: center;

  @media screen and (min-width: $smallDesktop) {
    justify-content: space-between;
  }

  &__logo {
    margin: 0 8px;
    color: $textColor;

    &:link {
      color: $textColor;
    }

    a:visited {
      color: $textColor;
    }

    a:active {
      color: $textColor;
    }

    a:hover {
      color: $primaryColorHover;
      background: none;
    }
  }
}
