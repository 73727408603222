@keyframes fadeInImage {
  from {
    opacity: 0%;
  }
  to {
    opacity: 50%;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadeInImage {
  from {
    opacity: 0%;
  }
  to {
    opacity: 50%;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeInImage {
  from {
    opacity: 0%;
  }
  to {
    opacity: 50%;
  }
}

/* Internet Explorer */
@-ms-keyframes fadeInImage {
  from {
    opacity: 0%;
  }
  to {
    opacity: 50%;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadeInImage {
  from {
    opacity: 0%;
  }
  to {
    opacity: 50%;
  }
}

.carousel {
  position: relative;
  z-index: 0;
  height: 100%;
  min-width: 320px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.slide {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: $secondaryTextColor;

  > * {
    opacity: 1;
  }

  &__content {
    height: calc(100vh - var(--navbar-height));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-sizing: border-box;

    h2 {
      padding: 24px 36px 0px 36px;
      text-align: center;
      @include size(36);
      line-height: 1.1;
      color: $textColor;
      text-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
      width: 100%;
      z-index: 1;
      position: relative;
      font-weight: 700;

      @media screen and (min-width: $smallDesktop) {
        @include size(48);
      }

      @media screen and (min-width: $monitor) {
        @include size(64);
      }
    }

    p {
      padding: 24px 48px;
      @include size(16);
      text-align: center;
      color: $textColor;
      width: 100%;
      z-index: 1;
      position: relative;
      font-weight: 500;

      @media screen and (min-width: $monitor) {
        @include size(24);
      }
    }

    > * {
      opacity: 0;
      transform: translateY(-45px);
      transition: all 1s;
    }

    .content__btn {
      transition-delay: unset !important;
      transition: unset !important;
    }
  }
}

.swiper {
  overflow: hidden;
  list-style: none;
  padding: 0;
  display: block;
  height: 100%;

  .swiper-wrapper {
    position: relative;
    z-index: 1;
    display: flex;
    transition-property: transform;
    transition-timing-function: var(
      --swiper-wrapper-transition-timing-function,
      initial
    );
  }
}

.content {
  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    object-fit: cover;
    object-position: center;
    opacity: 50%;
    height: 100%;
  }

  &__btn {
    width: fit-content;
    border: none;
    position: relative;
    margin-bottom: 24px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 12px;
    left: 0;
    right: 0;
    top: auto;
    @include size(18);
    color: $textColor;
    background-color: $primaryColor;
    display: block;
    padding: 12px 24px;
    text-decoration: none;
    border-radius: 8px;
  }
}

.swiper-slide {
  height: auto !important;
}

.swiper-slide-active {
  .content__image {
    animation: fadeInImage 5s forwards;
  }

  .slide {
    &__content {
      > * {
        opacity: 100%;
        transform: none;
      }
    }
  }
}

.swiper {
  .swiper-button-prev,
  .swiper-button-next {
    color: $textColor;
    width: 32px;
    height: 32px;
    border-radius: 50px;
    background-color: $primaryColor;
    margin: 0px;

    @media screen and (min-width: $smallDesktop) {
      bottom: 48px;
      width: 40px;
      height: 40px;

      &:hover {
        background: $primaryColorHover;
      }
    }

    @media screen and (min-width: $largeDesktop) {
      margin: -24px 24px 0 24px;
    }

    @media screen and (min-width: $smallDesktop) {
      .swiper-button-prev {
        left: 50%;
        margin-left: -44px;
        right: auto;
        top: -67px;
      }

      .swiper-button-next {
        right: auto;
        left: 50%;
        margin-left: 4px;
        bottom: 89px;
        top: -108px;
      }
    }

    &:after {
      font-size: 16px;
      font-weight: bold;

      @media screen and (min-width: $smallDesktop) {
        font-size: 20px;
      }
    }
  }
}
