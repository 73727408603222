@use "sass:math";

$smallMobile: 364px;
$mediumMobile: 375px;
$tablet: 475px;
$smallDesktop: 768px;
$largeDesktop: 991px;
$monitor: 1440px;
$textColor: #fff;
$secondaryTextColor: #111;
$primaryColor: #ec2c91;
$primaryColorHover: #c02377;
$secondaryColor: #fd89d9;
$mainColor: #f61875;
$mainColorHover: #c91962;
$hyperlinkColor: #e41e97;
$hyperlinkHover: #fd89d9;
$metadataColor: #989797;

@mixin size($sizeRequested) {
  letter-spacing: 0px;
  font-size: math.div($sizeRequested, 16) + rem;
}
