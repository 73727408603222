.under-construction {
  display: flex;
  flex-direction: column;
  background: $secondaryColor;
  height: 100vh;

  &__text-content {
    color: $textColor;
    padding: 20px;
  }

  &__title {
    color: $primaryColor;
    @include size(48);
    line-height: 1.2;
    text-align: center;
  }

  &__description {
    font-weight: 300;
    text-align: center;
    margin-top: 25px;

    p {
      margin-bottom: 15px;
    }
  }

  &__image-content {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 200px;
  }

  &__image {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
  }
}
