@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  color: $textColor;
  background: $secondaryTextColor;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: scroll;
  overflow-y: scroll;
  max-width: 100%;
  overflow-x: hidden;
}

.navbar + #home {
  margin-top: var(--navbar-height);
}

.navbar + .not-found {
  @media screen and (min-width: $smallDesktop) {
    margin-top: var(--navbar-height);
  }
}

.navbar + .news-list {
  @media screen and (min-width: $smallDesktop) {
    margin-top: var(--navbar-height);
  }
}

.navbar + .newspage {
  @media screen and (min-width: $smallDesktop) {
    margin-top: var(--navbar-height);
  }
}

.navbar + .privacy-policy {
  @media screen and (min-width: $smallDesktop) {
    margin-top: var(--navbar-height);
  }
}

::-webkit-scrollbar {
  @media screen and (min-width: $largeDesktop) {
    width: 8px;
  }
}

::-webkit-scrollbar-track-piece {
  @media screen and (min-width: $largeDesktop) {
    background: $secondaryTextColor;
  }
}

::-webkit-scrollbar-thumb {
  @media screen and (min-width: $largeDesktop) {
    background: $primaryColor;
    border-radius: 5px;
  }
}
